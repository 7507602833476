import React from 'react';
import { Helmet } from 'react-helmet';

const cropParameters = '?fit=crop&w=1200&h=630&fm=jpg';

export const Metadata = (props) => {
  return (
    <Helmet>
      <title>{ props.title }</title>
      <meta name="description" content={ props.description } />
      { props.image?.url && <meta property="og:image" content={ props.image.url + cropParameters} /> }
      { props.image?.url && <meta property="og:image:width" content="1200" /> }
      { props.image?.url && <meta property="og:image:height" content="630" /> }
    </Helmet>
  )
}
