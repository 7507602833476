import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import Facebook from './facebook.inline.svg';
import Linkedin from './linkedin.inline.svg';
import Twitter  from './twitter.inline.svg';

const openWindow = (url) => {
  window.open(
    url,
    'shareWindow',
    'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
  );
}

const facebook = () => {
  var url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href);
  openWindow(url);
}

const twitter = () => {
  var url = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href);
  openWindow(url);
}

const linkedin = () => {
  var url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(window.location.href);
  openWindow(url);
}

export const SocialShares = (props) => {
  const _classNames = classNames({
    'social-shares': true
  });
  
  return(
    <div className={_classNames}>
      <div className="social-shares__title">
        Share
      </div>
      <div className="social-shares__buttons">
        <div className="social-shares__button" onClick={linkedin}><Linkedin /></div>
        <div className="social-shares__button" onClick={facebook}><Facebook /></div>
        <div className="social-shares__button" onClick={twitter}><Twitter /></div>
      </div>
    </div>
  )
}
